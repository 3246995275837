<template>
  <div class="w-100">
    <label class="upload-file-cursor text-primary" @click="uploadClick()">
      <img class="mr-3" :src="resolve_img_url('cloud-upload-outline.svg')" />
      <label class="camelCase">{{ this.$props.description }}</label>
      <img v-if="this.files.length > 0" class="ml-3 mr-1" :src="resolve_img_url('MDI-paperclip.svg')" />
      <label v-if="this.files.length > 0" class="camelCase custom-label-file">{{ this.files.length }} arquivo(s) anexado(s)</label>
    </label>

    <div
      class="box-file"
      :class="this.$props.custonMaxheight ? 'box-file-max' : 'box-file-min'"
    >
      <div v-for="(file, index) in files" :key="file.name">
        {{ file.name }}
        <img v-on:click="removeFile(index)" class="ml-1" :src="resolve_img_url('path-close-circle-outline.svg')" />
      </div>
    </div>
    <input
      type="file"
      ref="file"
      class="input-file"
      :multiple="this.$props.multiple"
      v-on:change="handleFileUpload()"
      :accept="this.$props.accept"
      capture="user"
    />
    <div
      class="invalid"
      v-show="
        !this.$props.state && this.$props.state != null && this.$props.required
      "
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ this.$props.msgErro ? this.$props.msgErro : this.msgErroInvalid }}
    </div>
    <div
      class="invalid"
      v-show="
        this.$props.required && this.sizeFilesInvalid
      "
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ this.onGetMsgFileSizeErro() }}
    </div>
    <div class="mt-3" v-if="this.loadingFile" v-show="this.loadingFile">
      <p class="p">carregando... </p>
      <b-progress
          v-show="showProgress"
          :value="progress"
          :max="max"
          show-value
          class="mb-3"
        ></b-progress>
    </div>
  </div>
</template>

<script>
import resolve_img_url from "../../utils/image/image-utils";
import { base64toFile } from "../../utils/File/fileUtils";
import eventBus from "./eventBus.js";
import JSZip from 'jszip'

export default {
  name: "upload-file",
  props: [
    "accept",
    "multiple",
    "description",
    "state",
    "msgErro",
    "required",
    "custonMaxheight",
    "sizeFiles",
  ],
  created() {
    this.$root.$refs.UploadFile = this;
  },
  mounted() {
    
    eventBus.$on("attachFilesOnForm", (jsonSerializedFiles) => {
      this.attachFilesFromApp(jsonSerializedFiles);
    });
  },
  methods: {
    onGetMsgFileSizeErro() {
      return this.msgSizeErroFiles.replace("SIZE", this.$props.sizeFiles);
    },
    async uploadClick(){
      if(this.$store.getters.isApp) {
        window.upload_channel.postMessage(this.$props.multiple);
      }
      else {
        this.$refs.file.click();
      }      
    },
    async attachFilesFromApp(base64FileObjects) {
      var jsonString = atob(base64FileObjects);

      var jsObject = JSON.parse(jsonString);

      if (jsObject != null || jsObject != undefined) {
        for (const fileObject of jsObject) {
          var file = base64toFile(fileObject.content, fileObject.name);
          this.files.push(file);
        }
        this.files = [...this.$refs.file.files, ...this.files];
        
        const names = this.files.map((o) => o.name);
        this.files = this.files.filter(
          ({ name }, index) => !names.includes(name, index + 1)
        );
        
        this.$emit("updateFile", this.files);
      }  
    },
    resolve_img_url: resolve_img_url,
    handleFileUpload() {
      this.loadingFile = true;
      this.files = [...this.$refs.file.files, ...this.files];
      const names = this.files.map((o) => o.name);
      this.files = this.files.filter(
        ({ name }, index) => !names.includes(name, index + 1)
      );

      this.sizeFilesInvalid = this.$props.sizeFiles != null && this.$props.sizeFiles != undefined && (this.files.length > this.$props.sizeFiles);
      this.onZipFiles();

      this.$emit("sizeFilesValid", !this.sizeFilesInvalid);
      this.$emit("updateFile", this.files);
    },
    onZipFiles() {

      const totalFiles = this.files.length;
      const batchSize = 10;
      const batches = [];

      for (let i = 0; i < totalFiles; i += batchSize) {
        batches.push(this.files.slice(i, i + batchSize));
      }

      for (const batch of batches) {
        const logDate = new Date();
        const dataTime = `${this.formatDateUtc(logDate)}_${this.formatTimeUtc(logDate)}`;
        const zip = new JSZip();
        const filesFolder = zip.folder('files_' + dataTime)

        batch.forEach((element) => {
          filesFolder.file(element.name, element);
        })

        this.showProgress = true;

        zip.generateAsync({type:"blob"}, (metadata) => {
            this.progress = metadata.percent.toFixed(2);
        }).then((content) => {
          this.$emit("updateFileZip", content);
          this.loadingFile = false;
          this.showProgress = false;
          this.progress = 0;
        })
      }


      
    },

    hasFile() {
      return this.files && this.files.length > 0;
    },
    removeFile(index) {
      const fileListArrFile = Array.from(this.files);

      fileListArrFile.splice(index, 1);
      this.files = fileListArrFile;
      const dT = new ClipboardEvent("").clipboardData || new DataTransfer();
      for (let file of fileListArrFile) {
        dT.items.add(file);
      }
      this.$refs.file.files = dT.files;

      this.sizeFilesInvalid = this.$props.sizeFiles != null && this.$props.sizeFiles != undefined && this.files.length > this.$props.sizeFiles;
      this.$emit("sizeFilesValid", !this.sizeFilesInvalid);
      this.$emit("updateFile", this.files);
    },
    removeAllFiles() {      

      this.files = [];
      const dT = new ClipboardEvent("").clipboardData || new DataTransfer();
      this.$refs.file.files = dT.files;

      this.sizeFilesInvalid = false;
      this.$emit("sizeFilesValid", !this.sizeFilesInvalid);

      this.$emit("updateFile", this.files);
    },
    formatDateUtc(date) {
      return date.getUTCDate().toString().padStart(2, "0") + "_" + (date.getUTCMonth() + 1).toString().padStart(2, "0") + "_" + date.getUTCFullYear()
    }, 
    formatTimeUtc(date) {
      return date.getUTCHours().toString().padStart(2, "0") + "_" + date.getUTCMinutes().toString().padStart(2, "0") + "_" + date.getUTCSeconds().toString().padStart(2, "0")
    }
  },
  data() {
    return {
      files: [],
      msgErroInvalid: "É necessário o anexo do documento",
      msgSizeErroFiles: "É permitido no máximo SIZE arquivo(s)",
      sizeFilesInvalid: false,
      loadingFile: false,
      showProgress: false,
      progress: 0,
      max: 100,
    };
  },
};
</script>

<style lang="scss" scoped>
.upload-file-cursor {
  cursor: pointer;
  font-size: multiplier-default-font-size(3);
  align-items: center;
}

.input-file {
  display: none;
}

.box-file-max {
  max-height: 20rem;
}

.box-file-min {
  max-height: 5rem;
}

.box-file {
  min-height: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.invalid {
  color: var(--danger-2);
}

.img-reflesh {
  height: 60%;
  padding-right: 0.2rem;
  &--loadind {
    animation: spin 1s linear infinite;
  }
}

.custom-label-file {
  font-size: multiplier-default-font-size(2);
  color: var(--grey-3);
}

</style>
