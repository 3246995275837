<template>
  <div>
    <div 
        v-if="
            [
              DocumentType.EXTRACT,
              DocumentType.INCOME_REPORT,
            ].includes(form.fileType)
          " 
        class="info-header mb-3">
      <div>
        <p class="p3 color-green-2">
          Durante a importação, o sistema agrupará todos os números presentes no nome do arquivo para identificar o CRM.
        </p>
        <p class="p3 color-green-2">
          Por exemplo, se o arquivo for nomeado ‘{{ nameFileMsg }}’, o CRM será ‘5661’.
        </p>
      </div>
    </div>
    <b-form id="alter-form" class="alter-form" @submit="onSubmit">
      <b-card class="custom-card flex-grow-1" border-variant="light">
        <b-form-row>
          <b-col>
            Tipo
          </b-col>
          <b-col>
            <b-form-select
              v-model.lazy.trim="form.fileType"
              :state="validateField('fileType')"
              :options="fileTypeOptions"
              v-on:change="onChangeFileType(form.fileType)"
            />
          </b-col>
        </b-form-row>
        <b-form-row v-if="form.fileType === DocumentType.CALENDAR_INVOICES">
          <b-col>
            REFERÊNCIA
          </b-col>
          <b-col>
            <b-form-input v-model.lazy.trim="form.referency" />
          </b-col>
        </b-form-row>
        <b-form-row
          v-if="
            ![
              DocumentType.EXTRACT,
              DocumentType.INCOME_REPORT,
              DocumentType.ECONOMIC_PANEL,
              DocumentType.CALENDAR_INVOICES,
              DocumentType.ORGANIZATIONAL_CHART,
            ].includes(form.fileType)
          "
        >
          <b-col>
            CRM
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.crm"
              type="number"
              :state="validateField('crm')"
            />
          </b-col>
        </b-form-row>
        <b-form-row v-if="form.fileType !== DocumentType.CALENDAR_INVOICES">
          <b-col v-if="form.fileType == DocumentType.EXTRACT">
            Ano Calendário
          </b-col>
          <b-col v-else-if="form.fileType == DocumentType.ORGANIZATIONAL_CHART">
            ATUALIZADO EM
          </b-col>
          <b-col v-else>
            Competência
          </b-col>
          <b-col v-if="form.fileType == DocumentType.INCOME_REPORT">
            <custom-mask-input
              :initialValue="form.competency"
              placeHolder="AAAA"
              :options="yearOnlyMask"
              :state="validateFieldLength('competency', 4)"
            />
          </b-col>
          <b-col v-else>
            <custom-mask-input
              :initialValue="form.competency"
              placeHolder="MM/AAAA"
              :options="monthYearDateMask"
              :state="validateFieldLength('competency', 7)"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <UploadFile
              ref="upload-file-component"
              :accept="
                form.fileType !== DocumentType.SUPPORT_FUND
                  ? 'application/pdf'
                  : 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              "
              @updateFile="eventToUpdateFile"
              @sizeFilesValid="eventSizeFilesValid"
              @updateFileZip="eventToUpdateFileZip"
              description="Anexar documento"
              msgErro="É necessário inserir um anexo com a comprovação."
              :state="validateFieldFile('files')"
              :required="true"
              :multiple="true"
              :sizeFiles="sizeMaxFile"
              :custonMaxheight="true"
            />
          </b-col>
        </b-form-row>
      </b-card>
      <div class="w-100 d-flex">
        <b-button
          form="alter-form"
          type="submit"
          class="mt-4"
          variant="primary"
          id="submitButtonText"
        >
          Enviar
        </b-button>
        <div v-if="this.visibleDownloadReport" class="ml-4 mt-4">
          <div>
            <div
              class="custom-button-chart"
              @click="onDownloadReportHanlder"
            >
              <img src="@/assets/icons/MDI-download-outline.svg" />
              <div class="custom-text-chart ">Baixar relatório</div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import UploadFile from "../../../../components/upload-file/UploadFileZip.vue";
import {
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
} from "../../../../utils/validate/validate.js";
import { DocumentType } from "../../../../static-data/DocumentType";
import CustomMaskInput from "../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import moment from "moment";

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const MonthYearDateMask = {
  date: true,
  delimter: "/",
  datePattern: ["m", "Y"],
  onValueChanged: InvalidEventHandler,
};

const YearOnlyMask = {
  numericOnly: true,
  blocks: [4],
  onValueChanged: InvalidEventHandler,
};

const DefaultForm = {
  fileType: null,
  crm: null,
  competency: null,
  files: null,
};

export default {
  props: ["onSubmitHandler", "onGetFileBase64", "onChangeType", "onDownloadReport"],
  components: { UploadFile, CustomMaskInput},
  mounted() {
    this.initFileTypeOptions();
  },
  computed: {
    DocumentType() {
      return DocumentType;
    },
  },
  data() {
    return {
      fileTypeOptions: [],
      formSubmited: false,
      form: {
        ...DefaultForm,
        fileType: DocumentType.INCOME_REPORT,
        fileZip: []
      },
      yearOnlyMask: {
        ...YearOnlyMask,
        onValueChanged: this.onChangeCompetency,
      },
      monthYearDateMask: {
        ...MonthYearDateMask,
        onValueChanged: this.onChangeCompetency,
      },
      validations: {},
      validateField,
      requiredFieldsFilled,
      validateFieldFile,
      sizeFilesvalid: false,
      sizeMaxFile: 1,
      visibleDownloadReport: false,
      nameFileMsg: null,
    };
  },
  methods: {
    getNameFileMsg(fileType) {
      this.nameFileMsg = fileType === DocumentType.INCOME_REPORT 
                          ? "Informe_CRM_566_Rendimentos (1).pdf" 
                          : "Extrato_CRM_566_Cota (1).pdf"; 
    },
    validateFieldLength(name, requiredLength) {
      let value = this.form[name];
      let validation = true;
      validation = !!value;
      validation = validation && value.length == requiredLength;

      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    initFileTypeOptions() {
      this.fileTypeOptions = [
        {
          text: "Informe de Rendimentos",
          value: DocumentType.INCOME_REPORT, //year
        },
        {
          text: "Extrato de Cotas",
          value: DocumentType.EXTRACT,
        },
        {
          text: "Painel Econômico",
          value: DocumentType.ECONOMIC_PANEL,
        },
        {
          text: "Organograma",
          value: DocumentType.ORGANIZATIONAL_CHART,
        },
      ];

      this.onChangeFileType(DocumentType.INCOME_REPORT);
    },
    async onChangeFileType(fileType) {
      this.$refs["upload-file-component"].removeAllFiles();

      this.getNameFileMsg(fileType);

      await this.$props.onChangeType();
      this.visibleDownloadReport = false;

      this.sizeMaxFile = 1;
      if ([DocumentType.INCOME_REPORT, DocumentType.EXTRACT].includes(fileType)) {
        this.sizeMaxFile = 500;
      }

      if ([DocumentType.ECONOMIC_PANEL, DocumentType.CALENDAR_INVOICES, DocumentType.ORGANIZATIONAL_CHART].includes(fileType))
        this.onNotvalidateField("crm", fileType);

      if (fileType === DocumentType.CALENDAR_INVOICES)  
        this.onNotvalidateField("competency", fileType);
    },
    onNotvalidateField(name) {
      this.validations[name] = true;
    },
    onChangeCompetency(e) {
      this.form.competency = e.target.value;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    eventToUpdateFileZip(fileZip) {
      this.form.fileZip.push(fileZip);
    },
    eventSizeFilesValid(value){
      this.sizeFilesvalid = value;
    },
    async onSubmit(e) {
      if (this.form.fileType === DocumentType.CALENDAR_INVOICES) {
        this.form.competency = moment(Date.now()).format("MM/YYYY");
        this.form.crm = "-999";
      }
      e.preventDefault();
      this.formSubmited = true;
      if (this.requiredFieldsFilled() && this.sizeFilesvalid) {

        let file = {};
        await this.$props.onSubmitHandler(this.form);
        await this.$props.onGetFileBase64(file);
        this.visibleDownloadReport = file.visibleDownloadReport;
        
        this.formSubmited = false;
        this.form = {
          ...DefaultForm,
          fileType: DocumentType.INCOME_REPORT,
          fileZip: []
        },
        this.$refs["upload-file-component"].removeAllFiles();
      }
    },
    onDownloadReportHanlder(){
      this.$props.onDownloadReport(true);
    },
  },
};
</script>

<style lang="scss" scoped>
div.custom-card {
  padding: 0 !important;
}

div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}

.label-right {
  width: 100%;
  text-align: right;
}

.custom-button-chart {
  display: grid;
  grid-template-columns: 2.4rem auto;
  align-items: center;
  width: 17rem;
  height: 4.2rem;
  border-radius: 0.5rem;
  border: 1px solid #00995d;
  opacity: 1;
  padding-left: 1rem;
  cursor: pointer;
}

.custom-text-chart {
  color: var(--primary);
  font-size: 1.6rem;
  opacity: 1;
  padding-left: 1rem;
}
</style>