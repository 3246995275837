<template>
  <b-container class="d-flex" fluid>
    <PdfManagementForm class="custom-card-size" 
    :onSubmitHandler="onSubmit" 
    :onChangeType="onChangeTypeHandler" 
    :onGetFileBase64="onGetFileBase64Handler" 
    :onDownloadReport="onDownloadReport" />
    <SuccessModal
      ref="success-modal"
      description="Arquivo(s) importados com sucesso!"
      buttonText="Ok, entendi!"
    />
    <attention-modal
      modalId="pdf-file-attention-modal"
      title="Envio concluído!"
      cancelButtonText="OK, entendi!"
      submitButtonText="Baixar relatório"
      :onAction="onDownloadReport"
      ref="attetion-modal"
    >
      <template slot="description">
        <div class="justify-content-center text-center">
          <div>
            <p class="p1 mb-5">
              Dos {{this.sizeFilesError + this.sizeFilesSuccess}} arquivos enviados, {{this.sizeFilesSuccess}} foram entregues com sucesso. 
            </p>
          </div>
          <div v-if="this.sizeFilesError > 0">
            <p class="p1 mb-5">
              Tivemos problemas com o envio de {{this.sizeFilesError}} arquivos. Para mais detalhes, baixe o relatório.
            </p>
          </div>
        </div>
      </template>
    </attention-modal>
  </b-container>
</template>

<script>
import PdfManagementForm from "../forms/PdfManagementForm.vue";
import { documentPDFUpload } from "../../../../services/document-upload/document-file-service";
import { inject } from "@vue/composition-api";
import SuccessModal from '../../../../components/base-modals/SuccessModal.vue';
import AttentionModal from '../../../../components/base-modals/SendModal.vue';
export default {
  name: "file-management-content",
  components: {
    PdfManagementForm,
    SuccessModal,
    AttentionModal,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      loading: false,
      sizeFilesError: 0,
      sizeFilesSuccess: 0,
      reportData: null,
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.globalLoading = true;
        var response = await documentPDFUpload(formData);
        this.globalLoading = false;

        if (formData.fileType == "INCOME_REPORT" || formData.fileType == "EXTRACT") {
          this.sizeFilesError = response.sizeFilesError;
          this.sizeFilesSuccess = response.sizeFilesSuccess;
          this.reportData = response.list;

          const modal = this.$refs["attetion-modal"].$children[0];
          modal.show();
        } else {
          const modal = this.$refs["success-modal"].$children[0];
          modal.show();
        }  
      } catch (error) {
        this.globalLoading = false;
        console.error("Error OnSubmit", error);
      }
    },
    async onDownloadReport(action){
      if (action) {
        let csvContent = "data:text/csv;charset=utf-8,";
        const header = `Arquivo,Status`;
        csvContent += header + "\r\n";
        
        for (var i = 0; i < this.reportData.length; i++) {
          csvContent += `${this.reportData[i].fileName},${this.reportData[i].status}` + "\r\n";
        }

        const fileName = `relatorio_importacao.csv`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }


    },
    async onChangeTypeHandler() {
      this.sizeFilesError = 0;
      this.sizeFilesSuccess = 0;
      this.fileBase64 = null;
      this.extension = null;
    },
    async onGetFileBase64Handler(file) {
      file.visibleDownloadReport = this.fileBase64 != null;
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-card-size {
  width: 100%;
}
</style>
